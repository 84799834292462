export const SET_SHIFTS = "SET_SHIFTS";
export const SET_SHIFTS_COMPLETE = "SET_SHIFTS_COMPLETE";
export const SET_SHIFTS_DASHBOARD = "SET_SHIFTS_DASHBOARD";

export const SET_CURRENT_PANEL = "SET_CURRENT_PANEL";
export const SET_PANEL_STATE = "SET_PANEL_STATE";
export const SET_NETWORK_CONTACTS = "SET_NETWORK_CONTACTS";
export const SET_FORMER_COMPANIES = "SET_FORMER_COMPANIES";
export const SET_NETWORK_MESSAGES = "SET_NETWORK_MESSAGES";
export const SET_NETWORK_SENT_MESSAGES = "SET_NETWORK_SENT_MESSAGES";
export const SET_MESSAGE_FILTER = "SET_MESSAGE_FILTER";
export const SET_SHIFT_ROLE = "SET_SHIFT_ROLE";

export const SET_PAYMENT_HISTORY = "SET_PAYMENT_HISTORY";
export const SET_SHIFT_PRO_PERKS = "SET_SHIFT_PRO_PERKS";
export const SET_MODAL_PROPS = "SET_MODAL_PROPS";
export const SET_MESSAGE_MODAL_PROPS = "SET_MESSAGE_MODAL_PROPS";
export const SET_EMPLOYER_MODAL_CONFIG = "SET_EMPLOYER_MODAL_CONFIG";
export const SET_SHIFT_EDIT_MODAL_PROPS = "SET_SHIFT_EDIT_MODAL_PROPS";
export const SET_CLEAR_STATE = "SET_CLEAR_STATE";
export const SET_CANDIDATE_TIMESHEET = "SET_CANDIDATE_TIMESHEET";
export const SET_TASK_LIST = "SET_TASK_LIST";

export const SET_MESSAGE_THREAD = "SET_MESSAGE_THREAD";
export const SET_USER_RELIABILITY = "SET_USER_RELIABILITY";
