export const networkList = [
  {
    id: 1,
    parent: "Costa",
    subsidiary: "Costa Intu Watford",
    name: "Waiter",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    distance: "1 mi away",
  },
  {
    id: 2,
    parent: "Courtney Henry",
    subsidiary: "Costa Intu Watford",
    name: "Bartender",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    distance: "0.4 mi away",
  },
  {
    id: 3,
    parent: "Floyd",
    subsidiary: "Nandos Watford",
    name: "Chef",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    distance: "1.2 mi away",
  },
  {
    id: 4,
    parent: "Costa",
    subsidiary: "Costa Intu Watford",
    name: "CTO",
    imageUrl:
      "https://images.unsplash.com/photo-1517841905240-472988babdf9?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80",
    distance: "0.7 mi away",
  },
];
