import axios from "axios";
// const API_ENDPOINT = 'http://localhost:8080/api'
const API_ENDPOINT = "https://api.getAddress.io/";
const API_KEY = "v06pTzI3YEGId1I3Y9OlDA34456";

export const APIgetAddressSuggestion = (key) => {
  return axios.get(
    `${API_ENDPOINT}/autocomplete/${key}?api-key=${API_KEY}&top=20`,
    {
      withCredentials: false,
    }
  );
};

export const APIgetAddressDetail = (id) => {
  return axios.get(`${API_ENDPOINT}/get/${id}?api-key=${API_KEY}`, {
    withCredentials: false,
  });
};
