<template>
  <!-- <div v-if="isDuplicate" class=" bg-red-400 text-white p-4 mb-8">
            Username already exists
      </div> -->
  <Form
    @submit="handleFirstFormSubmittion"
    class="space-y-6"
    :validation-schema="isEmployer ? schemaEmployer : schemaCandidateFirstStep"
    v-slot="{ errors }"
    v-if="firstStepFlag"
  >
    <RadioGroup
      v-if="firstStepFlag || isEmployer"
      v-model="radioSelect"
      @click="handleGroupSelect"
    >
      <RadioGroupLabel class="sr-only"> Privacy radio </RadioGroupLabel>
      <div class="bg-white flex rounded-md -space-y-px w-full">
        <RadioGroupOption
          as="template"
          v-for="(radio, radioIdx) in radioOpt"
          :key="radio.name"
          :value="radio"
          v-slot="{ checked, active }"
        >
          <div
            :class="[
              radioIdx === 0 ? '' : '',
              radioIdx === radioOpt.length - 1 ? '' : '',
              checked ? 'bg-gray-100  z-10' : 'border-gray-200',
              'relative border lg:p-5 p-3 flex cursor-pointer focus:outline-none',
            ]"
          >
            <span
              :class="[
                checked
                  ? ' bg-emerald border-transparent'
                  : 'bg-white border-gray-300',
                active ? 'ring-2 ring-offset-2 ring-emerald' : '',
                'h-4 w-4 mt-0.5 cursor-pointer rounded-full border flex items-center justify-center',
              ]"
              aria-hidden="true"
            >
              <span class="rounded-full bg-white w-1.5 h-1.5" />
            </span>
            <div class="ml-3 flex flex-col">
              <RadioGroupLabel
                as="span"
                :class="[
                  checked ? ' text-emerald' : 'text-gray-900',
                  'block text-sm font-medium',
                ]"
              >
                {{ radio.name }}
              </RadioGroupLabel>
            </div>
          </div>
        </RadioGroupOption>
      </div>
    </RadioGroup>
    <div :class="{ hidden: !firstStepFlag, block: firstStepFlag }">
      <div v-if="isEmployer">
        <label for="company" class="mb-1 block text-sm font-bold text-gray-900">
          Company
        </label>
        <Field
          v-model="fields.company"
          placeholder="Company..."
          name="company"
          id="company"
          type="text"
          class="
            form-control
            appearance-none
            block
            w-full
            px-3
            py-2
            border border-gray-300
            rounded-md
            shadow-sm
            placeholder-gray-400
            focus:outline-none focus:ring-emerald focus:border-emerald
            sm:text-sm
          "
          :class="{ 'is-invalid': errors.company }"
        />
        <div class="invalid-feedback">{{ errors.company }}</div>
        <!-- <input v-model="fields.company" placeholder="Company..." name="company" id="company" type="text"  class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm"/>  -->
      </div>
      <div class="mt-3">
        <label for="username" class="block text-sm font-bold text-gray-700">
          Username
        </label>
        <div class="mt-1">
          <Field
            placeholder="Username..."
            v-model="fields.username"
            name="username"
            id="username"
            autocomplete="username"
            type="text"
            class="
              form-control
              appearance-none
              block
              w-full
              px-3
              py-2
              border border-gray-300
              rounded-md
              shadow-sm
              placeholder-gray-400
              focus:outline-none focus:ring-emerald focus:border-emerald
              sm:text-sm
            "
            :class="[
              {
                'is-invalid': errors.username,
              },
              {
                'is-invalid': isDuplicate,
              },
              {
                'is-invalid': isContainSpecial,
              },
            ]"
            @keyup="
              () => {
                isContainSpecial = false;
                isDuplicate = false;
                checkUserNameSpecial();
              }
            "
          />
          <div class="invalid-feedback">{{ errors.username }}</div>
          <div v-if="isDuplicate" class="invalid-feedback">
            Username already exists
          </div>
          <div v-if="isContainSpecial" class="invalid-feedback">
            Only letters and numbers are valid
          </div>
          <!-- <input placeholder="Username..." v-model="fields.username" id="username" name="username" type="text" autocomplete="username" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm" /> -->
        </div>
      </div>
      <div class="grid lg:grid-cols-2 gap-3 registerSection">
        <div class="col-span-1 mt-3">
          <label for="forename" class="block text-sm font-bold text-gray-900">
            Forename
          </label>
          <div class="mt-1">
            <Field
              placeholder="Forename..."
              v-model="fields.forename"
              name="forename"
              id="forename"
              autocomplete="forename"
              type="text"
              class="
                form-control
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none focus:ring-emerald focus:border-emerald
                sm:text-sm
              "
              :class="{ 'is-invalid': errors.forename }"
            />
            <div class="invalid-feedback">{{ errors.forename }}</div>
            <!-- <input placeholder="Forename..." v-model="fields.forename" id="forename" name="forename" type="text" autocomplete="forename" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm" /> -->
          </div>
        </div>

        <div class="col-span-1 mt-3">
          <label for="surname" class="block text-sm font-bold text-gray-900">
            Surname
          </label>
          <div class="mt-1">
            <Field
              placeholder="Surname..."
              v-model="fields.surname"
              name="surname"
              id="surname"
              autocomplete="surname"
              type="text"
              class="
                form-control
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none focus:ring-emerald focus:border-emerald
                sm:text-sm
              "
              :class="{ 'is-invalid': errors.surname }"
            />
            <div class="invalid-feedback">{{ errors.surname }}</div>
            <!-- <input placeholder="Surname..."  v-model="fields.surname" id="surname" name="surname" type="surname" autocomplete="surname" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm" /> -->
          </div>
        </div>
      </div>

      <div class="mt-3">
        <label for="email" class="block text-sm font-bold text-gray-700">
          E-mail address
        </label>
        <div class="mt-1">
          <Field
            placeholder="Email..."
            v-model="fields.email"
            name="email"
            id="email"
            autocomplete="email"
            type="text"
            class="
              form-control
              appearance-none
              block
              w-full
              px-3
              py-2
              border border-gray-300
              rounded-md
              shadow-sm
              placeholder-gray-400
              focus:outline-none focus:ring-emerald focus:border-emerald
              sm:text-sm
            "
            :class="{ 'is-invalid': errors.email }"
          />
          <div class="invalid-feedback">{{ errors.email }}</div>
          <!-- <input placeholder="Email..." v-model="fields.email" id="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm" /> -->
        </div>
      </div>

      <div class="grid lg:grid-cols-2 gap-3 mt-3">
        <div class="col-span-1">
          <label for="forename" class="block text-sm font-bold text-gray-900">
            Password
          </label>
          <div class="mt-1">
            <Field
              placeholder="Password..."
              v-model="fields.password"
              name="password"
              id="password"
              type="password"
              class="
                form-control
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none focus:ring-emerald focus:border-emerald
                sm:text-sm
              "
              :class="{ 'is-invalid': errors.password }"
            />
            <password-meter :password="fields.password" />
            <div class="invalid-feedback">{{ errors.password }}</div>
            <!-- <input placeholder="Forename..."  type="password" v-model="fields.password" id="forename" name="forename"  autocomplete="password" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm" /> -->
          </div>
        </div>

        <div class="col-span-1">
          <label for="surname" class="block text-sm font-bold text-gray-900">
            Confirm password
          </label>
          <div class="mt-1">
            <Field
              placeholder="Confirm Password..."
              v-model="fields.confirmPassword"
              name="confirmPassword"
              id="confirmPassword"
              type="password"
              class="
                form-control
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none focus:ring-emerald focus:border-emerald
                sm:text-sm
              "
              :class="{ 'is-invalid': errors.confirmPassword }"
            />
            <div class="invalid-feedback">{{ errors.confirmPassword }}</div>
            <!-- <input placeholder="Surname..." type="password"  v-model="fields.confirmPassword" id="surname" name="surname" autocomplete="confirmPassword" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm" /> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="firstStepFlag && !isEmployer">
      <button
        type="submit"
        class="btn w-full primaryButton flex justify-center"
      >
        <span class="align-center mr-1">Next</span>
        <ChevronRightIcon class="w-5 mr-1 self-center" />
      </button>
    </div>
    <div v-if="!firstStepFlag || isEmployer" class="flex justify-center">
      <button type="submit" class="btn w-full primaryButton">Register</button>
    </div>
  </Form>
  <Form
    v-if="!firstStepFlag"
    @submit="handleSubmittion"
    class="space-y-6"
    :validation-schema="schemaCandidateSecondStep"
    v-slot="{ errors }"
  >
    <div v-if="!firstStepFlag">
      <div>
        <div class="grid lg:grid-cols-1">
          <div class="relative">
            <input
              placeholder="Start typing your address"
              class="
                form-control
                text-gray-500
                shadow-sm
                hover:shadow-md
                focus:ring-emerald focus:border-emerald
                block
                w-full
                sm:text-sm
                border border-gray-300
                rounded-md
                p-3
                mb-2
              "
              @keyup="(e) => changeInputSuggestion(e)"
            />
            <div
              v-click-away="onClickAway1"
              v-if="suggestionListShowFlag"
              class="
                absolute
                bg-white
                w-full
                p-3
                border border-gray-300
                rounded
                mw-200
              "
              style="max-height: 359px; overflow: auto"
            >
              <p
                v-for="suggestion in suggestionList"
                :key="suggestion.id"
                class="cursor-pointer pb-2 hover:text-emerald w-full"
                @click="setPlace(suggestion)"
              >
                {{ suggestion.address }}
              </p>
            </div>
          </div>
          <div>
            <label for="forename" class="block text-sm font-bold text-gray-900"
              >Address line 1</label
            >
            <div class="mt-1 autocomplete-div">
              <Field
                v-model="fields.address1"
                name="address1"
                type="text"
                class="
                  form-control
                  text-gray-500
                  shadow-sm
                  hover:shadow-md
                  focus:ring-emerald focus:border-emerald
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="{ 'is-invalid': errors.address1 }"
              />
              <div class="invalid-feedback">{{ errors.address1 }}</div>
            </div>
          </div>
          <div class="mt-1">
            <label for="forename" class="block text-sm font-bold text-gray-900"
              >Address line 2</label
            >
            <div class="mt-1">
              <input
                type="text"
                v-model="fields.address2"
                class="
                  text-gray-500
                  shadow-sm
                  hover:shadow-md
                  focus:ring-emerald focus:border-emerald
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
              />
            </div>
          </div>
        </div>

        <div class="grid lg:grid-cols-2 gap-2">
          <div class="mt-2">
            <label for="forename" class="block text-sm font-bold text-gray-900"
              >Town/City</label
            >
            <div class="mt-1">
              <Field
                v-model="fields.city"
                name="city"
                type="text"
                class="
                  form-control
                  text-gray-500
                  shadow-sm
                  hover:shadow-md
                  focus:ring-emerald focus:border-emerald
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="{ 'is-invalid': errors.city }"
              />
              <div class="invalid-feedback">{{ errors.city }}</div>
            </div>
          </div>
          <div class="mt-2">
            <label for="forename" class="block text-sm font-bold text-gray-900"
              >County</label
            >
            <div class="mt-1">
              <Field
                v-model="fields.county"
                name="county"
                type="text"
                class="
                  form-control
                  text-gray-500
                  shadow-sm
                  hover:shadow-md
                  focus:ring-emerald focus:border-emerald
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="{ 'is-invalid': errors.county }"
              />
              <div class="invalid-feedback">{{ errors.county }}</div>
            </div>
          </div>
          <div>
            <label for="forename" class="block text-sm font-bold text-gray-900">
              Post Code
            </label>
            <Field
              placeholder="Post code..."
              v-model="fields.postCode"
              name="postCode"
              id="postCode"
              type="text"
              class="
                form-control
                appearance-none
                block
                w-full
                px-3
                py-2
                border border-gray-300
                rounded-md
                shadow-sm
                placeholder-gray-400
                focus:outline-none focus:ring-emerald focus:border-emerald
                sm:text-sm
              "
              :class="{ 'is-invalid': errors.postCode }"
            />
            <div class="invalid-feedback">{{ errors.postCode }}</div>
            <!-- <input placeholder="Email..." v-model="fields.email" id="email" name="email" type="email" autocomplete="email" required="" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-full shadow-sm placeholder-gray-400 focus:outline-none focus:ring-emerald focus:border-emerald sm:text-sm" /> -->
          </div>
          <div>
            <label for="forename" class="block text-sm font-bold text-gray-900"
              >Country</label
            >
            <div class="mt-1">
              <Field
                value="United Kingdom"
                name="country"
                type="text"
                class="
                  form-control
                  text-gray-500
                  shadow-sm
                  hover:shadow-md
                  focus:ring-emerald focus:border-emerald
                  block
                  w-full
                  sm:text-sm
                  border-gray-300
                  rounded-md
                "
                :class="{ 'is-invalid': errors.country }"
              />
              <div class="invalid-feedback">{{ errors.country }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!firstStepFlag">
      <button
        @click="handleFirstStepBackBtn()"
        class="btn w-full secondaryButton flex justify-center"
      >
        <ChevronLeftIcon class="w-5 mr-1 self-center" />
        <span> Back </span>
      </button>
    </div>
    <div class="flex justify-center">
      <button type="submit" class="btn w-full primaryButton">Register</button>
    </div>
  </Form>
</template>
<script>
import { RadioGroup, RadioGroupLabel, RadioGroupOption } from "@headlessui/vue";
// import { useStore } from 'vuex'
import { ref, reactive } from "vue";
import { Form, Field } from "vee-validate";
import * as Yup from "yup";
import { mixin as VueClickAway } from "vue3-click-away";

import PasswordMeter from "vue-simple-password-meter";
import { APIdoCheckUserName } from "@/api/authentication/api";

import {
  APIgetAddressSuggestion,
  APIgetAddressDetail,
} from "@/api/autoFill/api";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/vue/outline";
const radioOpt = [
  { code: 2, name: "I am a candidate" },
  { code: 3, name: "I am an employer" },
];

export default {
  components: {
    RadioGroup,
    RadioGroupLabel,
    RadioGroupOption,
    Form,
    Field,
    PasswordMeter,
    ChevronLeftIcon,
    ChevronRightIcon,
  },
  data: () => ({
    suggestionList: [],
    suggestionListShowFlag: false,
  }),
  mixins: [VueClickAway],

  mounted() {},
  methods: {
    onClickAway1() {
      this.suggestionListShowFlag = false;
    },

    setPlace(suggestion) {
      this.suggestionListShowFlag = false;
      APIgetAddressDetail(suggestion.id).then((res) => {
        this.fields.postCode = res.data.postcode;
        this.fields.address1 = res.data.line_1;
        this.fields.address2 = res.data.line_2;
        this.fields.county = res.data.county;
        this.fields.city = res.data.town_or_city;
      });
    },
    changeInputSuggestion(e) {
      APIgetAddressSuggestion(e.target.value).then((res) => {
        this.suggestionList = res.data.suggestions;
        this.suggestionListShowFlag = true;
      });
    },
    // handleFirstStepNextBtn() {
    //   if (
    //     this.fields.forename !== "" &&
    //     this.fields.surname !== "" &&
    //     this.fields.email !== "" &&
    //     this.fields.password !== "" &&
    //     this.fields.password === this.fields.confirmPassword &&
    //     this.fields.username !== ""
    //   )
    //     this.firstStepFlag = false;
    // },
    handleFirstStepBackBtn() {
      this.firstStepFlag = true;
    },
  },

  setup(props, context) {
    const schemaEmployer = Yup.object().shape({
      username: Yup.string().required("Username is required").min(8),
      forename: Yup.string().required("Forename is required"),
      surname: Yup.string().required("Surname is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
      company: Yup.string().required("Company is required"),
    });
    const schemaCandidateFirstStep = Yup.object().shape({
      forename: Yup.string().required("Forename is required"),
      surname: Yup.string().required("Surname is required"),
      email: Yup.string()
        .required("Email is required")
        .email("Email is invalid"),
      password: Yup.string().required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm password is required"),
      username: Yup.string().required("Username is required").min(8),
    });
    const schemaCandidateSecondStep = Yup.object().shape({
      postCode: Yup.string().required("Post code is required"),
      city: Yup.string().required("City is required"),
      county: Yup.string().required("County is required"),
      address1: Yup.string().required("Address is required"),
    });
    // const store = useStore()
    const isEmployer = ref(false);
    const isDuplicate = ref(false);
    const firstStepFlag = ref(true);

    const isContainSpecial = ref(false);
    const radioSelect = ref(radioOpt[0]);
    const fields = reactive({
      forename: "",
      surname: "",
      company: "",
      email: "",
      password: "",
      confirmPassword: "",
      username: "",
      postCode: "",
      address1: "",
      address2: "",
      county: "",
      city: "",
    });

    const handleGroupSelect = () => {
      if (radioSelect.value.code === 3) isEmployer.value = true;
      else isEmployer.value = false;
    };
    const handleFirstFormSubmittion = async () => {
      if (isEmployer.value) {
        await checkUserName();
        if (!isContainSpecial.value && !isDuplicate.value) {
          handleSubmittion();
        }
      } else {
        await checkUserName();
        if (!isContainSpecial.value && !isDuplicate.value) {
          firstStepFlag.value = false;
        }
      }
    };

    const handleSubmittion = () => {
      context.emit("collectFields", {
        ...fields,
        usergroup: radioSelect.value.code,
      });
    };
    const checkUserNameSpecial = () => {
      let format = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/;
      if (format.test(fields.username)) {
        isContainSpecial.value = true;
        return true;
      } else {
        isContainSpecial.value = false;
        return false;
      }
    };
    const checkUserName = async () => {
      let format = /[!@#$%^&*()_+\-=\]{};':"\\|,.<>?]+/;
      if (format.test(fields.username)) {
        isContainSpecial.value = true;
        return false;
      } else {
        await APIdoCheckUserName({
          action: "check",
          username: fields.username,
        }).then((res) => {
          if (res.data.success) {
            console.log("efe");
            return true;
          } else {
            isDuplicate.value = true;
            firstStepFlag.value = true;
            return false;
          }
        });
      }
    };

    return {
      schemaCandidateFirstStep,
      schemaCandidateSecondStep,
      schemaEmployer,
      radioOpt,
      radioSelect,
      fields,
      isEmployer,
      isDuplicate,
      isContainSpecial,
      checkUserName,
      handleSubmittion,
      handleFirstFormSubmittion,
      handleGroupSelect,
      checkUserNameSpecial,
      firstStepFlag,
    };
  },
};
</script>
